@import '../../../node_modules/@springernature/brand-context/nature/scss/core';

.content {
	max-width: 1000px;
	margin: 0 auto;
}

.grade-c-hide {
	display: none;
}

.js .js-hide {
	display: none;
}

.u-icon {
	width: 1em;
	height: 1em;
}
